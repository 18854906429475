import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'

const StyledWrapper=styled.div`
.main-heading{
    color: black;
    font-size:42px;
    text-align:center;
    font-weight:600;
    font-family:'Helvetica Neue';
    font-stretch:100%;
    text-size-adjust:100%;
    padding-top:30px;
    padding-bottom:10px;
    margin-top:10px;
    margin-bottom:24px;
    @media(max-width:768px){
        font-size:28px;
    }
}
.main-subheading{
    color:black;
    font-size:16px;
    font-family:'Helvetica Neue','Helvetica';
    font-weight:600;
    margin-block-end:20px;
    text-size-adjust:100%;
}
.main-text{
    color:black;
    font-size:16px;
    margin-block-end:20px;
    font-family:'Helvetica Neue','Helvetica';
    font-weight:400;
    line-height:27.2px;
    text-size-adjust:100%;
}
ul.main-li{
    color:black;
    font-size:16px;
    margin-block-end:20px;
    font-family:'Helvetica Neue','Helvetica';
    font-weight:400;
    padding-left:40px;
    line-height:27.2px;
    text-size-adjust:100%;
}

.text-anchor{
    color:black;
    text-decoration:underline;
}
`
function PrivacyPolicy() {
    return (
        <Layout>
        <StyledWrapper>
        <div className="padding-left-8 padding-right-8">
                <h1 className="main-heading">PRIVACY NOTICE & USE OF COOKIES</h1>
                    <ol>
                    <li className="main-subheading"><strong>Purpose</strong></li>
                    <p className="main-text">upReach Charitable Company (upReach) as organiser of the Student Social 
                    Mobility Awards (SSMA) has prepared this Privacy Notice to outline our practices regarding 
                    the collection, use, disclosure, transfer and other processing of individually 
                    identifiable information about you (“Personal Information”) collected when you use 
                    the SSMA website. upReach will process any Personal Information fairly and lawfully, 
                    in accordance with this Privacy Notice and in accordance with the General Data Protection 
                    Regulation (“GDPR”) and the Data Protection Act 2018.</p>
                        
                    <p className="main-text">In accordance with GDPR definitions, upReach, Studio 18, Blue Lion 
                    Place, 237 Long Lane, London, SE1 4PU is the Data Controller, with the CEO and Trustees of 
                    upReach therefore ultimately responsible for its implementation. upReach has designated Gavin 
                    Davis, the person responsible for Data Protection matters for the SSMA. Her contact details can 
                    be found at the end of this document.</p>

                   
                    
                    
                    <li className="main-subheading"><strong>Information collection and use</strong></li>
                    <p className="main-text">While using our website and in our subsequent correspondence with you, 
                    we may ask you to provide us with certain personally identifiable information that can be used 
                    to contact or identify you. Personally identifiable information may include, but is not limited 
                    to your name and contact details (“Personal Information”).</p>
                        
                    <p className="main-subheading"><strong>A) Personal Information Collection</strong></p>
                    <p className="main-text">Subject to this Notice, upReach will treat as confidential the 
                    Personal Information that it collects about you. upReach may collect the following categories 
                    of Personal Information:</p>
                    <p className="main-text"><em>(1) For potential or actual nominees:</em> Your name, contact information 
                    (email address, address and telephone number), educational details including the name of your 
                    university, details relating to any work experience or job that you may have had or will have 
                    (including salary information), personal interests and academic/career aspirations.  Any 
                    information over and above this that you share with SSMA in your submission will be shared 
                    with upReach representatives and the judging panel and may be used in Awards publicity material. 
                    Any additional information you submit via REALrating will not be shared beyond upReach representatives 
                    and the judging panel without your permission.</p>
                    <p className="main-text"><em>(2) For nominating parties, supporters, including employers, universities,
                         mentors, volunteers, donors and subscribers: </em>Your name, contact information, employment information
                          and your bank or card details if applicable. .</p>
                        
                    <p className="main-subheading"><strong>B) Purposes of Use of Personal Information and Legal Basis</strong></p>
                    <p className="main-text">upReach may use the Personal Information listed above for the following purposes:</p>
                    <p className="main-text"><em>(1) For potential or actual nominees: </em>  To process your nomination for an award, 
                    various administrative purposes in connection with the operation of the awards including for contacting you to 
                    request more information or notify you of the outcome of your nomination, statistical purposes, promotional purposes, 
                    awards evaluation purposes and to keep you informed about SSMA’s work. If you decide you no longer want to receive 
                    any such services or communications, you have the right to inform us and opt-out.</p>
                    <p className="main-text"><em>(2) For nominating parties, supporters, including employers, universities, 
                    mentors, volunteers, donors and subscribers:</em>  To process the information you have provided 
                    in relation to the SSMA, various administrative purposes in connection with the operation of the 
                    awards including contacting you about the awards, awards evaluation purposes, fundraising purposes 
                    and for the purpose of keeping you up to date with our activity relating to our existing relationship. 
                    <strong> It is the responsibility of the nominating party to obtain consent from the nominee to share their 
                    information with SSMA. By consenting to this Privacy Notice you are agreeing to upReach sharing the 
                    information you provide with the nominee, upReach representatives and the judging panel.</strong></p>
                    <p className="main-text"><em>(3) Photography</em> – For potential or actual nominees and any person attending 
                    the Awards: Shortlisted nominees may be asked to provide a photograph for use in Awards publicity material. 
                    If invited to attend the awards, photographs of all attending may be taken and used in Awards publicity material.  
                    You will be given an opportunity when confirming your attendance at the Awards to confirm if you do NOT want to be photographed.</p>
                    
                    <li className="main-subheading"><strong>Disclosure and international transfers of personal information</strong></li>
                    <p className="main-text"><em>(1) For potential or actual nominees:</em> For the purposes detailed above your Personal 
                    Information may be stored on MailChimp.com, the service we use to maintain our distribution lists.</p>
                    <p className="main-text"><em>(2) For nominating parties, supporters, including employers, universities, mentors, volunteers, 
                        donors and subscribers:</em> For the purposes of sending you Awards updates your Personal Information may be stored on 
                        MailChimp.com, the service we use to maintain our distribution lists. You can opt-out from these communications at anytime.</p>
                    <p className="main-text">If necessary and in accordance with applicable laws, upReach may disclose personal 
                    data to our outside professional advisers and to other third parties processors that provide products or services 
                    to upReach, such as IT systems providers.</p>
                   <p className="main-text">Where the processing of personal data is delegated to a third 
                   party data processor, upReach will choose a data processor that provides sufficient guarantees with 
                   respect to technical and organisational security measures governing the relevant processing and will 
                   ensure that the processor acts on our behalf and under our instructions.</p>
                   <p className="main-text">Where third party processing or storage takes place outside the United Kingdom 
                   or EEA (European Economic Area), upReach recognises these as ‘restricted transfers’ and conducts an 
                   ‘adequacy assessment’ to ensure the proposed transfer will provide an adequate level of protection for 
                   the rights of the data subjects and takes steps to establish appropriate data protection and information 
                   security requirements with recipients to confirm that data is properly protected in accordance with this 
                   Notice and all applicable laws.</p>

                    <li className="main-subheading"><strong>Use of Cookies</strong></li>
                    <p className="main-text">This site uses cookies to optimise your user experience. Cookies are files with small amount of data, which may
                        include an anonymous unique identifier. Cookies are sent to your browser from a web site
                        and stored on your computer’s hard drive.</p>
                    <p className="main-text">By using this site you are consenting to our use of these cookies.</p>

                    <li className="main-subheading"><strong>Log Data</strong></li>
                    <p className="main-text">Like many site operators, we collect information that your browser sends whenever you visit our website
                        (“Log Data”).</p>   
                    <p className="main-text">This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, 
                    browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent 
                    on those pages and other statistics. This helps us to improve the site by monitoring how you use it.We may use third party 
                    services such as Google Analytics and Hotjar to collect, monitor and analyze this in order to help us measure traffic and 
                    usage trends for the website. We collect and use this analytics information
                    in aggregate form such that it cannot reasonably be manipulated to identify any particular individual user.</p>
                   

                    <li className="main-subheading"><strong>Changes to the Notice</strong></li> 
                    <p className="main-text">Should upReach  decide to substantially modify the manner in which it collects or uses Personal Information, 
                    the type of Personal Information that it collects or any other aspect of this Notice, upReach  will notify you as soon as 
                    possible of such changes by re-issuing a revised Notice on our website <span><a className="text-anchor" href="http://studentsocialmobilityawards.org.uk">(http://studentsocialmobilityawards.org.uk)</a></span>.</p>

                    <li className="main-subheading"><strong>Accuracy of and access to your personal information (subject access requests)</strong></li>
                    <p className="main-text">You are entitled to request and access the information that upReach holds about you (subject to limited exceptions), 
                    as stated in General Data Protection Regulation (GDPR). In addition, you have the right to have inaccurate Personal Information 
                    corrected or removed and to object to the processing of your Personal Information. If you wish to access such Personal Information, 
                    you should apply in writing to the person on the contact details below or at the email address set out on our website 
                    <span><a className="text-anchor" href="http://studentsocialmobilityawards.org.uk">(http://studentsocialmobilityawards.org.uk)</a></span>.</p>
                    <p className="main-text">To assist us in maintaining accurate Personal Information, you must advise us of any changes to your Personal Information. 
                    In the event that upReach becomes aware of any inaccuracy in the Personal Information that it has recorded, upReach will correct that 
                    inaccuracy at the earliest practical opportunity.</p> 

                    <li className="main-subheading"><strong>Retention of Data</strong></li>  
                    <p className="main-text"><em>(1) Winners and shortlisted nominees:</em> upReach will retain information pertaining 
                    to SSMA winners and shortlisted nominees for the purpose(s) for which it was collected for 6 (six) years from the date of the event.</p>
                    <p className="main-text"><em>(2) Other nominees: </em>  Information pertaining to other nominees will be deleted 12 months from the date 
                    of the event.</p>
                    <p className="main-text"><em>(3) For nominating parties, supporters, including employers, universities, mentors, volunteers, donors, subscribers 
                    and Award attendees:</em> upReach will retain any information provided for the purpose(s) for which it was collected for 
                    up to 6 (six) years.  If you decide you no longer want to receive any such communications, you have the right to inform 
                    us and opt-out.</p>

                    <li className="main-subheading"><strong>Security</strong></li>
                    <p className="main-text">upReach maintains appropriate technical and organisational security measures 
                    including staff training to protect Personal Information against accidental or unlawful destruction, or 
                    accidental loss, alteration, unauthorised disclosure or access, in compliance with applicable laws. </p>
                    
                    <li className="main-subheading"><strong>Links to Other Websites and Services</strong></li>
                    <p className="main-text">upReach is not responsible for the practices employed by websites or services 
                    linked to or from its website <span><a className="text-anchor" href="http://studentsocialmobilityawards.org.uk">(http://studentsocialmobilityawards.org.uk)</a></span> including the information or 
                    content contained therein. Please remember that when you use a link to go from this site to another website, 
                    our Privacy Notice does not apply to third-party websites or services. Your browsing and interaction on any 
                    third-party website or service, including those that have a link or advertisement on our website, are subject 
                    to that third party’s own rules and policies. </p>
                    
                    <li className="main-subheading"><strong>Questions?</strong></li>
                    <p className="main-text">Please address all questions to Gavin Davis, Finance Manager at gavin.davis@upreach.org.uk.</p>
                    </ol>

                <h1 className="main-heading">Glossary of Terms</h1>

                    <p className="main-subheading"><strong>Adequacy Assessment</strong></p>
                    <p className="main-text">An assessment of the risk of transferring data outside the EEA ensuring protection is adequate in all
                     the circumstance of the case. The assessment considers the nature of the data, the risk to the rights of the individual, 
                     the purposes and period of transfer.</p>
                    
                    <p className="main-subheading"><strong>Consent</strong></p>
                    <p className="main-text">GDPR defines this as any freely given specific and informed indication of his wishes by which 
                    the data subject signifies his agreement to personal data relating to him being processed. Consent can be withdrawn 
                    after it has been given.</p>
                    <p className="main-text">Where data is “sensitive”, express consent is always sought from the data subject before 
                    the data can be given to a third party. </p>

                    <p className="main-subheading"><strong>Data</strong></p>
                    <p className="main-text">Any information which will be processed, or, used on or by a computerised system, additionally 
                    it also includes information contained within a “relevant filing system” of information. Data can therefore be written, 
                    tape, photographic or digital. </p>

                    <p className="main-subheading"><strong>Personal Data</strong></p>
                    <p className="main-text">Personal data means any information relating to a living individual who can be identified:</p>
                    <p className="main-text">Examples of data which would fall into this category include:</p>
                    <ul className='main-li'>
                        <li>Name</li>
                        <li>Gender</li>
                        <li>Date of birth</li>
                        <li>University details</li>
                        <li>Emails, phone number and personal address</li>
                    </ul>

                    <p className="main-subheading"><strong>Special Category Data</strong></p>
                    <p className="main-text">This means data which relates to more sensitive aspects of a living and identifiable individual’s life</p>
                    <p className="main-text">Data which falls into this category includes:</p>
                    <ul className='main-li'>
                        <li>Race</li>
                        <li>Ethnic origin</li>
                        <li>Politics</li>
                        <li>Religion</li>
                        <li>Trade Union Membership</li>
                        <li>Genetics</li>
                        <li>Biometrics (when used for ID purposes)</li>
                        <li>Health</li>
                        <li>Sex life</li>
                        <li>Sexual orientation</li>
                    </ul>
                    <p className="main-subheading"><strong>Data Subject</strong></p>
                    <p className="main-text">The person who is the subject of the “personal data”. </p>
                    
                    <p className="main-subheading"><strong>Data Controller</strong></p>
                    <p className="main-text">A person who determines the purposes for which, and the manner in which, 
                    any personal data are, or are to be, processed.</p>
                    <p className="main-subheading"><strong>Data Processor </strong></p>
                    <p className="main-text">Any person (other than an employee of the data controller) who processes data on 
                    behalf of the data controller. The data controller retains responsibility for the actions of the data processor.</p>
                    <p className="main-subheading"><strong>Limited Exceptions</strong></p>
                    <p className="main-text">Processing of personal and sensitive data including responding to a Subject Access 
                    Request may, in rare circumstances, be restricted when personal data is subject to situations involving 
                    “crime and taxation purposes” which include:</p>
                    <ul className='main-li'>
                        <li>the prevention or detection of crime;</li>
                        <li>the capture or prosecution of offenders; and</li>
                        <li>the assessment or collection of tax or duty.</li>
                    </ul>
                    <p className="main-subheading"><strong>Processing</strong></p>
                    <p className="main-text">Covers almost anything which is done with or to the data, including:</p>
                    <ul className='main-li'>
                        <li>obtaining data </li>
                        <li>recording or entering data onto the files</li>
                        <li>holding data, or keeping it on file without doing anything to it or with it </li>
                        <li>organising, altering or adapting data in any way </li>
                        <li>retrieving, consulting or otherwise using the data</li>
                        <li>disclosing data either by giving it out, by sending it on email, or simply by making it available </li>
                        <li>combining data with other information </li>
                        <li>erasing or destroying data </li>
                        <li>using the data within research</li>
                    </ul>

                    <p className="main-subheading"><strong>Recipient</strong></p>
                    <p className="main-text">Any person to whom the data are disclosed, including any person to whom they 
                    are disclosed in the course of processing the data for the Data Controller (e.g. an employee of the data 
                    controller, a data processor or employee of the data processor). </p>
                    
                    <p className="main-subheading"><strong>Restricted Transfer</strong></p>
                    <p className="main-text">A transfer of personal data outside the protection of the GDPR most often involves a transfer 
                    from inside the EEA to a country outside the EEA.</p>
                    
                    <p className="main-subheading"><strong>Subject Access Request</strong></p>
                    <p className="main-text">The process by which individuals can find out what personal or sensitive data an 
                    organisation holds about them, why they hold it and who they disclose it to.</p>

                    <p className="main-subheading"><strong>Third Party</strong></p>
                    <p className="main-text">A natural or legal person, public authority, agency or body other than the data subject, 
                    controller, processor and persons who, under the direct authority of the controller or processor are authorised 
                    to process personal data.</p>
                    <hr/>
                    <p className="main-subheading"><strong>A-15:SSMA Privacy Notice and Use of Cookies<br/>
                    Prepared by: Nicola Lewis<br/>
                    Effective from: 03/09/2018<br/>
                    Review on or before: 03/09/2019
                    </strong></p>
                   
            </div>
            </StyledWrapper>
    </Layout>
    )
}

export default PrivacyPolicy
